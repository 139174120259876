import React, {useState} from 'react';

export interface Item {
  id: number;
  name: string;
}

function ListItem(props: { item: Item }) {
  const [count, setCount] = useState(props.item.id);
  return <button onClick={() => setCount(count + 1)}>Button {props.item.name} (clicked: {count})</button>
}

const initialItems = [{id: 3, name: `3`}, {id: 2, name: `2`}, {id: 1, name: `1`}]

function List() {
  const [count, setCount] = useState(4);
  const [list, setList] = useState<Item[]>(initialItems);

  function generateItem(): Item {
    setCount(count + 1);
    return {id: count, name: `${count}`}
  }

  function addItemFront() {
    setList([generateItem(), ...list])
  }

  function changeReference(items: Item[]) {
    setList(JSON.parse(JSON.stringify(items)));
  }

  function shuffle(items: Item[]) {
    setList([...items].sort(() => Math.random() - 0.5));
  }

  function remove(items: Item[]) {
    setList(items.slice(1));
  }

  return <>
    <h1>React List Key Demo</h1>
    <p>
      Add, shuffle or remove buttons. Buttons are initialised with their id. Click on the created buttons to
      increase the click count. You can also mess with the current id.
    </p>
    <p>
      <label>Current Id:
        <input type="number" value={count} onChange={(event: any) => setCount(Number(event.target.value))}/>
      </label>
    </p>
    <p>
      <button type={'button'} onClick={() => addItemFront()}>Add button</button>
      <button type={'button'} onClick={() => shuffle(list)}>Shuffle</button>
      <button type={'button'} onClick={() => remove(list)}>Remove button</button>
      <button type={'button'} onClick={() => changeReference(list)}>Change all references</button>
    </p>

    <h2>No Key parameter</h2>
    <p>Dev warning</p>
    <div>{list.map((item) => <ListItem item={item}></ListItem>)}</div>

    <h2>Key = index</h2>
    <p>Default</p>
    <div>{list.map((item, index) => <ListItem item={item} key={index}></ListItem>)}</div>

    <h2>Key = item.id</h2>
    <div>{list.map((item) => <ListItem item={item} key={item.id}></ListItem>)}</div>

    <h2>Key = 42</h2>
    <p>Runtime Warning</p>
    <div>{list.map((item) => <ListItem item={item} key={42}></ListItem>)}</div>

    <h2>Key = Math.floor(item.id/2)</h2>
    <p>Runtime Warning</p>
    <div>{list.map((item) => <ListItem item={item} key={Math.floor(item.id / 2)}></ListItem>)}</div>

    <h2>Key =item.id % 2</h2>
    <p>Runtime Warning</p>
    <div>{list.map((item) => <ListItem item={item} key={item.id % 2}></ListItem>)}</div>
  </>
}

function App() {
  return (
    <List></List>
  );
}

export default App;
